//All the style are getting effected from "my-app\src\components\Requests\ViewRequest.scss"
.draftButton {
  background: #1e7976 0 0 no-repeat padding-box;
}
.draftLoader {
  width: 100%;
}
.removeDraft {
  position: absolute;
  right: 2%;
  top: -4%;
  padding: 10px 0px;
  cursor: pointer;
}
.myReqList-mainContainer {
  width: 90%;
  margin: 0 auto;
  .MuiSnackbar-root {
    position: static;
  }
}

// .draftReqList{
//   margin-top: 5%;
// }

.cancelDraftDialog {
  .MuiTypography-h6 {
    display: flex;
  }

  .MuiSvgIcon-root {
    fill: #09577a !important;
    margin-top: 10px;
  }
}

.doNotCancel {
  background: #09577a 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border: 1px solid #09577a !important;
  min-width: 20% !important;
  border-radius: 5px;
  text-transform: none !important;
  margin: 5% 0 5% 5% !important;
  box-shadow: none;
}

.cancelBtnContainer {
  font-family: "Roboto";
  .cancelClickedReq {
    min-width: 20% !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #e1e1e1 !important;
    border-radius: 5px;
    text-transform: none !important;
    margin: 5% 0 5% 0;
    color: #0f1c49;
    box-shadow: none;

    &:hover {
      background: #09577a 0% 0% no-repeat padding-box !important;
      color: #fff !important;
      border: 1px solid #09577a !important;
      opacity: 0.8;
    }
  }
}

.noRequestAvailable {
  font-size: 14px;
  margin: 10px;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    margin-top: 10%;
  }
}
