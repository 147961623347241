@media screen and (min-width: 320px) and (max-width: 1024px) {
  .MuiDialog-paper {
    margin: 16px !important;

    h2 {
      font-size: 14px !important;
      font-weight: 400;
    }
    p {
      font-size: 12px !important;
      font-weight: 400;
    }
    button {
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .home-mainContainer {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;

    .topPicksHome article {
      margin-top: 50px;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .homeServInfo {
      padding-bottom: 10px;
    }

    .topPicksHome section div:nth-child(1) {
      width: 100%;
      height: 250px;
      // position: fixed;
      position: absolute;
      margin-left: 0;
      padding: 0px;
      //autoscroll of toppicks
      // overflow-x: auto;
      // overflow-y: hidden;
      white-space: nowrap;
      article {
        width: 160px;
        text-align: center;
        height: 170px;
        display: inline-table;
        flex-wrap: nowrap;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin: 0;
        section {
          width: 10em !important;
        }
      }
    }
  }

  .MobileHomePage-popover {
    .MuiDialog-scrollPaper {
      display: flex !important;
      width: 100% !important;
      min-height: 200px;
      //margin: 40vh auto !important;
    }
    .MuiButton-root {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .carousel-indicators {
    display: none;
  }
}

$animationSpeed: 40s;

// Animation
@keyframes topicksScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 3));
  }
}
.toppicks_section_mobile {
  article {
    animation: topicksScroll 30s linear infinite;
  }
  overflow-x: hidden;
}
