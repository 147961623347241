.MobileBottomNavigationWrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 90vw;
  display: flex;
  justify-content: center;
  padding: 0 0px;
  border-radius: 10px;
  margin: 0 5vw;
  z-index: 999;
  box-shadow: -3px 7px 13px #0000004d;

  .MuiBottomNavigation-root {
    height: 70px;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    filter: drop-shadow(-3px 7px 13px #d4f0fc85);
    a {
      display: flex;
      justify-content: center;
      min-width: 20%;
      button.event-wrapper {
        margin-left: -10px;
      }
      button.notification-wrapper {
        margin-left: 10px;
      }
    }
    .MuiBottomNavigationAction-wrapper {
      height: 30px;
      img {
        height: 60px;
        margin-top: -15px;
        &.active + .MuiBottomNavigationAction-label {
          color: #09577a !important;
        }
      }
      .MuiBottomNavigationAction-label {
        height: 14px;
        text-align: center;
        margin-top: -10px;
      }

      .request-icon {
        border-radius: 50%;
        padding: 10px;
        background-color: #09577a;
        color: #fff;
        margin-top: -40px;
        border: 5px solid #fff;
        height: 60px;
        width: 60px;

        span {
          font-size: 20px;
          padding: 10px;
        }
      }
      .request-icon + .MuiBottomNavigationAction-label {
        width: 70px;
        line-height: 10px;
        margin-top: 14px;
      }
      .active + .MuiBottomNavigationAction-label {
        color: #09577a !important;
      }
    }

    .MuiBottomNavigationAction-root {
      color: #a7b5cf;
      padding: 0;
      min-width: 20%;
    }
    .MuiBottomNavigationAction-label {
      opacity: 1;
      font-size: 0.6rem;
    }
  }
}
