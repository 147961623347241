@import "../../../shared.scss";

.footer-section--full {
  font-size: 0.82rem !important;
  color: #fff;
  font-size: 14px;
  width: 90%;

  .footer-directory img {
    width: 10rem;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #fff !important;
  }
}

.rightSectionBlock1-footer {
  button {
    float: right;
  }
}

.footercolumn {
  ul {
    list-style: none;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
  }
}

.footerReqService {
  padding-top: 2%;
}

.footerReqBtn {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.leftSectionBlock1-footer p {
  margin-bottom: 0.5rem;
}

.footer-col-left {
  li {
    margin: 5px;
  }
}

.footer-col-right {
  width: 40%;
  margin-left: 10%;

  .footercolumn {
    width: 40%;
    h3 {
      color: #fff;
    }
  }
}

.footer-col-leftConatiner {
  width: 50%;
}

.copyright {
  text-align: center;
  margin: 1%;
}

.address {
  margin-top: 2%;
  white-space: pre-line;
}
.footerlink {
  cursor: pointer;
}
