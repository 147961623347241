@import "print.scss";
html {
  /* width */

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .mobileWrapperContentPages ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px;
  }
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 0px !important;
      height: 0px;
    }
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 120px;
  }

  ::-webkit-scrollbar-thumb:hover {
    // background: #ccc;
    background: #e0e0e0;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfbfc !important;
  overflow-x: hidden;
  width: 100vw;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  a {
    text-decoration: none !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  display: none;
}
