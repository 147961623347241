/*
 * UserProfile css File
*/

.userProfile-rootContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  padding: 2%;
  height: calc(100vh - 70px);
  @media screen and (min-width: 320px) and (max-width: 374px) {
    margin: 40px auto;
    width: 95%;
    flex-direction: column;
    height: calc(100vh - 250px);
  }
  @media screen and (min-width: 375px) and (max-width: 1024px) {
    width: 95%;
    flex-direction: column;
    margin: 0 auto;
    height: calc(100vh - 250px);
  }
  @media screen and (min-width: 1367px) and (max-height: 1560px) {
    width: 80%;
    padding: 8%;
  }
  @media screen and (min-width: 1561px) and (max-height: 1920px) {
    width: 80%;
    padding: 5% 10%;
  }

  .doctorProfileImg {
    position: relative;
    // margin: 0 auto;
    img {
      width: 50%;
      min-height: 50%;
      border-radius: 50%;
    }

    .profileImgDivContainer {
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      background-repeat: no-repeat;
      @media screen and (max-width: 1024px) {
        width: 100px;
        height: 100px;
      }
    }

    // @media screen and (min-width: "1360px") {
    //   width: 15rem;
    //   height: 15rem;
    // }
    .cameraImg {
      position: absolute;
      bottom: 0;
      float: right;
      background-color: #09577a;
      border-radius: 50%;
      padding: 5px;
      border: 3px solid #fff;
      width: 40px;
      height: 40px;
      top: 170px;
      right: 0;
      cursor: pointer;
      @media screen and (max-width: 1024px) {
        top: 85px;
      }

      .MuiSvgIcon-root {
        fill: #fff;
      }

      @media screen and (min-width: 1367px) and (max-height: 1560px) {
        // right: 0;
        // top: 110px;
      }
      @media screen and (min-width: 1561px) and (max-height: 1920px) {
        width: 40px;
        height: 40px;
        // right: 0;
        // top: 150px;
      }
    }
  }

  .hcpProfileDesc {
    color: #0f1c49;
    padding-right: 0px;
    text-align: right;
    @media screen and (max-width: 1024px) {
      text-align: left;
      margin-left: 5%;
    }

    .hcpSpecs {
      font-size: 16px;
      // opacity: 80%;
      font-weight: bold;
      text-align: center;
      @media screen and (max-width: 1024px) {
        text-align: left;
      }
    }

    .hcpLegacyCode {
      color: #707070;
      font-size: 0.82rem;
      font-weight: bold;
      // opacity: 60%;
    }
  }

  .leftCol {
    border-right: 3px solid #efefef;
    padding: 40px 80px;
    margin-left: 1%;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 1024px) {
      flex-direction: row;
      height: 50%;
      padding: 0 0;
      margin-left: 2%;
      align-items: center;
      border-right: none;
      border-bottom: 2px solid #efefef;
    }
  }

  .rightCol {
    padding: 40px;
    .hcpContactDetails {
      @media screen and (max-width: 1024px) {
        padding-left: 15px !important;
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  .cameraImg {
    float: right;
  }

  .hcpContactField {
    &:first-of-type {
      height: 6rem !important;
      @media screen and (min-width: 320px) and (max-width: 1024px) {
        height: auto !important;
      }
    }

    display: flex;
    // height: 3rem;
    text-align: left;
  }

  .hcpContactText {
    margin-left: 25px;
  }

  .hcpContactTextTitle {
    font-size: 0.9rem;
  }

  .hcpContactTextValue {
    font-size: 0.9rem;
    color: #707070;
    font-weight: normal;
    // opacity: 60%;
  }

  .hcpSpecs {
    color: #707070;
  }
}

.hcpAddress {
  height: 3em;
  width: 30em;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    height: auto;
    width: 20em;
  }
}
