$searchIcon: #464646;
$searchIconFont: 18px;
$textFieldFontColor: #464646;
$textFieldFontSize: 0.9rem;
$roboto:'Roboto';
$titletextColor: #295788;
$fontFamily: 'Roboto';
$tablet-width: 1024px;
$desktop-width: 1024px;
$small: 300px;
$medium: 735px;
$formFieldColor:#0F1C49;


$pendingStatus: #FF7700;
$completedStatus: #098800;
$submitStatus:#09577A;

body {
  font-family: $fontFamily;
  background-color: #fcfbfc !important;
  overflow-x: hidden;
  width: 100vw;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.displayBlock{
  display:block !important;
  font-size: 0.82rem;
}

.displayNone{
  display: none !important;
  font-size: 0.82rem;
}


.visibilityVisible{
  visibility: visible;
}

.visibilityHidden{
  visibility: hidden;
}

.MuiBottomNavigation-root {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
.mobileWrapperContentPages{
  height:82vh;
  overflow: auto;
  margin:9vh 0;
  padding-bottom:70px;
 
}
}

.carousel-indicators{
  display: none;
}

.reqListContainer-mobile{
  margin-top: -2em;
}

button:disabled,
button[disabled]{
  opacity: .4;
}

.MuiAlert-message{
  white-space: pre-line;
}


.spinner-grow {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
}