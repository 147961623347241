.MobileWraper {
    .background-mobile {
      position: absolute;
      z-index: -1;
      width: 100%;
      // height:100vh;
      height: calc(100vh - 60px);
      img {
        width: 100%;
        height: 100vh;
      }
    }
    .login-container-mobile {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      .mobile-image-wrapper {
        height: 54%;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
  
        .mobile-left-docter {
          img {
            height: 35vh;
            width: 100%;
            padding-left: 5vw;
            padding-top: 5vh;
            margin-left: 4vw;
            margin-top: 10vh;
          }
        }
        .mobile-right-docter {
          img {
            height: 40vh;
            width: 90%;
          }
        }
      }
      .mobile-login-wrapper {
        height: 46%;
        display: flex;
        flex-direction: column;
        padding: 0 8.5vw;
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          //tabKaChange
          padding: 0 17.5vw;
        }
  
        .mobile-login {
          min-height: 20vh;
          h1 {
            font-weight: bold;
            color: #295788;
            padding: 25px 0 0;
          }
          .mobile-logo {
            display: flex;
            img {
              width: 50%;
              float: left;
              height: 100%;
              margin: 0;
              padding: 0;
            }
          }
          .otpCredential-mobile {
            .resendOtpSec {
              text-align: center !important;
              padding: 20px 0 !important;
              .resendOTPLink {
                text-align: center !important;
                color: #118ff0 !important;
                font-size: 14px !important;
              }
            }
            .otpContainer {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin: 0;
  
              .otpInput {
                width: 3.3rem !important;
                height: 3.5rem;
                margin: 0;
                border-radius: 12px;
                font-size: 1.3rem;
                border: 1px solid #a8a8a8;
                &:active,
                &:focus,
                &:visited,
                &:focus-visible {
                  outline: none;
                }
              }
            }
          }
          .loginCredential-mobile {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 0.5px solid #d6d6d6;
            border-radius: 10px;
            opacity: 1;
            padding-left: 10px !important;
            padding: 10px 0px;
            width: 100%;
            //box-shadow: 0px 10px 10px #0000000f;
            .MuiSvgIcon-root {
              margin-top: 2px;
            }
            .MuiTextField-root {
              width: 90%;
            }
            .MuiInput-underline {
              &::before {
                border-bottom: none;
              }
              &::after {
                border-bottom: none !important;
              }
            }
            input {
              width: 100%;
              margin-left: 15px;
              color: #000000d9;
              &::placeholder {
                color: #000000d9;
                opacity: 1;
              }
            }
          }
        }
  
        .mobile-button {
          min-height: 25vh;
          .submit-button {
            width: 100%;
            text-transform: initial !important;
            background: #09577a !important;
            box-shadow: 0px 3px 6px #00000029 !important;
            border-radius: 10px !important;
            padding: 22px 0 19px 0;
            margin: 25% 0 0 0;
            font: normal normal normal 16px/19px Roboto;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            transition: 500ms;
            &.submit-botton-enabled {
              margin: 25% 0 0 0;
            }
          }
        }
      }
    }
  }
  