/*
 * Login css File
*/
@import "../../shared";

#login-container,#otp-container {
  background: url(../../assets/images/LoginBg.svg) no-repeat center fixed;
  min-height: calc(100vh - 1rem - 50px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#logo {
  height: 5em;
  width:100%;

  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    margin-right: 10rem;
  }
  @media screen and (min-width: 1366px) and (min-height: 1024px) {
    margin-right: 12rem;
  }
}

#logo img {
  float: left;
  height: 100%;
  margin: 4% 0 0 5%;
}

.left-container {
  text-align: left;
  height: 100vh;
}
.right-container {
  height: 100vh;
}

#loginDetails {
  width: 100%;
  padding: 10px 120px 10px 30px;
  @media screen and (min-width: 1367px) and (max-height: 1560px) {
    padding: 10px 140px 10px 30px;
  }
  @media screen and (min-width: 1561px) and (max-height: 1920px) {
    padding: 10px 270px 10px 30px;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    color: $titletextColor;
    font-family: $roboto;
    // padding-left: 1rem;
  }

  p {
    font-size: 25px;
    font-family: $fontFamily;
  }

  input {
    width: 250px;
    font-size: $textFieldFontSize;
    height: 25px;
    color: #09577a;
    // border-bottom: 1px solid #09577a;
  }

  input::placeholder {
    font-size: $textFieldFontSize;
  }

  input:focus {
    outline: none;
  }

  .MuiInputBase-root {
    border-bottom: 1px solid #09577a;
    width:100%;
  }

  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    margin-right: 20%;
  }
}

#goToOTP,
#goToHome {
  color: white;
  width: 50px;
  height: 50px;
  // background: transparent linear-gradient(90deg, #0A358E 0%, #2D7BEB 49%, #53BFF9 100%) 0% 0% no-repeat padding-box;
  background: #09577a;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
}
.dottedBlocksLogin {
  width: 132px;
  height: 110px;
  float: left;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 50%;
  section {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #53bff9;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.verifyUserLink {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.login-imgContainer {
  height: 100%;
  position: relative;
}
.login-imgContainer img {
  float: right;
  height: 100%;
  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    height: 85%;
  }
  // @media screen and (min-width: 992px)  {
  //   width: 90%;
  //   height: 50%;
  // }
  @media screen and (min-height: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    height: 45%;
  }
}

.signInContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 85vh;
  padding: 0 15%;
  @media screen and (min-height: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    height: 48vh;
  }
}

.validateUname {
  color: red;
  font-size: 0.9rem !important;
}

.credential-Entered {
  font-size: 0.9rem;
  margin-bottom: 2%;
}

.ringSection {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #09577a;
  bottom: 35%;
  left: 58%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    left: 45%;
  }
  div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
  }

  @media screen and (min-width: 1440px) {
    left: 45%;
  }
  @media screen and (min-width: 1600px) {
    left: 50%;
  }
  @media screen and (min-width: 1920px) {
    left: 50%;
  }
}

.loginCredentialContainer,
.otpCredentialContainer {
min-height: 4rem;
}
.loginCredentialContainer{
  .MuiFormControl-root{
    width:100%;
  }
}

.semiCircle {
  position: fixed;
  top: 0;
  left: 85%;
  border-radius: 150px 150px 0 0;
  height: 50px;
  width: 100px;
  background-color: #2d7beb;
  transform: rotate(180deg);
}

.ringSection-sec {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2d7beb;
  top: 15%;
  left: 95%;
  position: absolute;
  display: none; //flex
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    // left: 60%;
  }
  div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
  }
}

.doctorImgContainer {
  display: flex;
  height: 100%;

  .loginimagecontainer {
    .loginImg1 {
      //  height: 24rem;
      img {
        width: 16rem;
        height: 22rem;
        @media screen and (min-width: 1920px) {
          width: 25rem;
          height: 30rem;
        }
      }
    }
  }
  .loginimagecontainer {
    display: flex;
    align-items: flex-end;
    .loginImg2 {
      height: 38rem;
      @media screen and (min-width: 1920px) {
        height: 55rem;
      }

      img {
        width: 25rem;
        padding-top: 5rem;
        @media screen and (min-width: 1920px) {
          width: 35rem;
        }

      }
    }
  }
}

.disableLoginBtn{
  pointer-events: none;
  opacity: 0.4;
}