.supporticket-mainContainer {
  width: 92%;
  margin: 0 auto;
  height: 70vh;
  overflow: auto;
  .reqListContainer {
    margin-top: 3.5em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.supportTicketMobile {
  margin-top: 5em;
}
