@import "../../shared";
.home-mainContainer {
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    margin-top: 30px;
  }
}
.pullMarketingContent {
  .carousel-item {
    width: 100% !important;
    float: none;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;

    // min-height:55vh;
    @media screen and (max-width: 1024px) {
      min-height: 30vh;
    }
    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      min-height: 448px;
    }
    @media screen and (min-width: 1367px) and (max-height: 1560px) {
      min-height: 560px;
    }
    @media screen and (min-width: 1561px) and (max-height: 1920px) {
      min-height: 600px;
    }
  }
  .carousel-control-prev-icon {
    width: 54px;
    border-radius: 50%;
    height: 54px;
    //  background-size: 30% 45%;
    background-repeat: no-repeat;
    opacity: 0.5;
    background-size: 30px;

    &:hover {
      background-color: #000;
    }
  }
  .carousel-control-next-icon {
    width: 54px;
    border-radius: 50%;
    height: 54px;
    //  background-size: 30% 45%;
    background-repeat: no-repeat;
    opacity: 0.5;
    background-size: 30px;
    &:hover {
      background-color: #000;
    }
  }

  .carousel-caption {
    top: 20%;
    right: 0 !important;
    left: 0% !important;
    color: #000 !important;

    p {
      font-weight: 400;
    }
  }

  .carousel-inner {
    cursor: pointer;
    width: 95%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #00000033;
    // min-height:55vh;
    @media screen and (max-width: 1024px) {
      min-height: 30vh;
    }
    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      min-height: 448px;
    }
    @media screen and (min-width: 1367px) and (max-width: 1560px) {
      min-height: 560px;
    }
    @media screen and (min-width: 1561px) and (max-width: 1920px) {
      min-height: 600px;
    }

    img {
      border-radius: 40px;
      min-height: 70vh;
      @media screen and (min-width: 1024px) and (max-width: 1366px) {
        min-height: 448px;
      }
      @media screen and (min-width: 1367px) and (max-width: 1560px) {
        min-height: 560px;
      }
      @media screen and (min-width: 1561px) and (max-width: 1920px) {
        min-height: 600px;
      }
    }
  }
}

.carousel-indicators {
  bottom: 80px !important;
}

.user-activityPanel {
  position: relative;
  z-index: 2;
  top: -120px !important;
  width: 80%;
  min-height: 260px;
  margin: 0 auto;
  background: transparent
    linear-gradient(81deg, #ffffff 0%, #ffffff99 48%, #ffffffcc 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 10px 26px #00000029;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);

  @media screen and (min-width: 1366px) {
    min-height: 12rem;
  }

  @media screen and (min-width: 1440px) {
    top: 40%;
  }

  /* Portrait */
  @media all and (max-width: 1024px) and (orientation: portrait) {
    top: 40%;
  }

  @media all and (min-width: 1024px) and (orientation: portrait) {
    top: 30%;
  }

  /* Landscape */
  @media all and (max-width: 1024px) and (orientation: landscape) {
    top: 30%;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    top: 40%;
  }
}

.categoryOptionHeaders {
  display: flex;
  border-bottom: 1px solid #00000029;
  width: 90%;
  margin: 0 auto;
  cursor: pointer;

  article {
    width: 20%;
    margin: 10px 10px 0px 10px;
    height: 2em;
    color: #000;
  }

  .selectedOptionCategory {
    font-weight: 600;
    border-bottom: 2px solid #000;
  }
}

.categoryOptionContent-service {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  article {
    margin: 10px 1% 0px 1%;
    text-align: center;
    color: #000;
    width: 18%;
    position: relative;
    z-index: 2;
  }
}

.usvAboutUs {
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-top: 5rem;
  transition: all 500ms;
  /* (1920x1080) Full HD Display */
  @media screen and (min-width: 1920px) {
    margin-top: 8rem;
  }

  .aboutUsDesc {
    padding-top: 5em;
  }

  .aboutUs-img img {
    width: 30rem;
  }
}
.topPicksHome {
  width: 95%;
  margin: 0 auto;
  margin-top: -3em;
  transition: all 500ms;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
  article {
    display: flex;
    justify-content: center;
    color: #0f1c49;
    font-size: 1.6rem;

    // padding-bottom: 40px;
  }
  section {
    display: flex;
    width: 100%;
    height: 18.5rem;
    display: flex;
    align-items: center;
    position: relative;
    @media screen and (min-width: 1367px) and (max-height: 1560px) {
      min-height: 200px;
    }
    @media screen and (min-width: 1561px) and (max-height: 1920px) {
      min-height: 220px;
    }
    @media screen and (max-width: 1024px) {
      height: 180px;
    }
    // div:first-child{
    //   width: 54px;
    //   border-radius: 50px;
    //   height: 54px;
    //   // background-color: #707070;
    //   background-repeat: no-repeat;
    //   // opacity: 0.5;
    //   background-size: 30px;
    //   padding: 15px;
    //   margin-top: 20px;
    // }

    div:nth-child(2) {
      width: 98%;
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 78%;
      position: relative;
      margin-left: 0%;
      padding: 30px;

      @media screen and (min-width: 1920px) {
        height: 100%;
      }
    }

    div:nth-child(3) {
      cursor: pointer;
      width: 1%;

      display: flex;

      align-items: center;

      overflow: hidden;

      height: 78%;

      position: relative;

      margin-left: 0%;

      padding: 30px;

      @media screen and (min-width: 1920px) {
        height: 100%;
      }
    }

    div:nth-child(1) {
      width: 1%;

      height: 78%;
      position: relative;
      margin-left: 0;
      padding: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media screen and (min-width: 1920px) {
        height: 100%;
      }
      @media screen and (min-width: 1025px) {
        overflow: hidden;
      }
      img {
        position: absolute;
        min-height: 160px;
        @media screen and (min-width: 1367px) and (max-height: 1560px) {
          min-height: 200px;
        }
        @media screen and (min-width: 1561px) and (max-height: 1920px) {
          min-height: 220px;
        }
      }
    }

    // div:last-child{
    //   width: 54px;
    //   border-radius: 50px;
    //   height: 54px;
    //   // background-color: #707070;
    //   background-repeat: no-repeat;
    //   // opacity: 0.5;
    //   background-size: 30px;
    //   padding: 15px;
    //   margin-top: 20px;
    // }
  }
  @media screen and (min-width: 1920px) {
    margin-top: -35px;
  }
}
// .usvAboutUsMargin{
//   margin-top: calc(11em + 5rem);
// }
.aboutUsDesc {
  width: 40%;
  text-align: left;
  margin-left: 10%;
  h3 {
    color: #09a4d8;
    font-size: 20px;
    font-family: $fontFamily;
  }
  div {
    font-size: 14px;
    text-align: left;
  }
}

footer {
  background-color: $titletextColor;
}

.liveChat-container {
  border: 1px solid;
  background: #fff;
  box-shadow: 5px 10px #888888;
  width: 20%;
  position: fixed;
  z-index: 5;
  left: 80%;
  top: 30%;
  min-height: 50%;
}

.hcpFooterContainer {
  //background-color: $titletextColor;
  background: transparent linear-gradient(180deg, #53bff9 0%, #0a358e 100%) 0%
    0% no-repeat padding-box;
}

.allServiceImg {
  img {
    position: absolute;
    z-index: 2;
    width: 25rem;
    right: 0;
    bottom: 20%;
  }
}

.user-activityPanel {
  div {
    header {
      // border-radius: 10px;
      box-shadow: none !important;
      // border-bottom: 1px solid #e9e9e9;
      border-bottom: 1px solid #09577a;
      background: none;
    }

    .Mui-selected {
      font-size: 0.9rem;
      color: #09577a;
      //  text-shadow: 1px 1px #afcedc;
    }

    button {
      font-size: 0.75rem;
    }

    .MuiTabs-indicator {
      background-color: #09577a !important;
    }
  }
}

.serviceListContainer {
  width: 80%;
  margin: 0 auto;
  background-color: #53bff91a;
  position: relative;
  border-top-left-radius: 30px;
}

// .cimg{
//   background-image: url('../../assets/images/carousel.jpg');
//   width:100%;
//   min-height: 680;
//   border-radius: 20px;
// }

.cimg1 {
  background-image: url("../../assets/images/first.png");
  width: 100%;
  min-height: 680;
  border-radius: 20px;
}

.cimg2 {
  background-image: url("../../assets/images/carousel2.jpg");
  width: 100%;
  min-height: 680;
  border-radius: 20px;
}

.serviceListDesc {
  margin-left: 3rem;

  h3 {
    color: #0f1c49;
    font-family: "Roboto";

    font-weight: 500;
  }
}
.homeServicesWrapper {
  min-height: 400px;
  padding: 5%;
  padding-top: 0%;
}
.homeServInfo {
  article {
    margin-top: 2rem;
    display: flex;
    div {
      padding: 1rem;
      box-shadow: 1px 2px 3px 3px rgba(202, 202, 202, 0.2);
      border-radius: 4px;
      min-height: 210px;

      section {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: 09577a;
        background: #1042611a;
        img {
          width: 70%;
          height: 50%;
        }
      }
      label {
        font-weight: 600;
        padding-top: 1rem;
      }
      p {
        font-size: 0.8rem;
        padding-top: 0.4rem;
      }
    }
  }
}

.homeServRowClass {
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1920px) {
    padding: 0 7rem;
  }
}
.homeServInfo {
  padding-bottom: 150px;
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    padding: 0;
  }
}

.homeServiceParagraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    padding: 0;
  }

  article {
    font-size: 1.4rem;
    line-height: 30px;
    padding: 0 2rem;
    text-align: center;
    letter-spacing: 0px;
    color: #0f1c49;
    opacity: 1;
    @media screen and (min-width: 1024px) and (max-width: 1920px) {
      font-size: 2.2rem;
      font-weight: 300;
      line-height: 50px;
      padding: 0 3rem;
      text-align: left;
      letter-spacing: 0px;
      color: #0f1c49;
      opacity: 1;
    }
  }
  p {
    font-size: 0.82rem;
    opacity: 0.7;
    margin-top: 1rem;
    padding: 0 2rem;
    text-align: center;
    @media screen and (min-width: 1024px) and (max-width: 1920px) {
      font-size: 0.82rem;
      opacity: 0.7;
      margin-top: 1rem;
      padding: 0 3rem;
      text-align: left;
    }
  }
}

.homeTopPicksBg {
  left: 0%;
  transition: all 200ms ease 0s;
  height: calc(100% - 60px);
  width: 20%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  margin: 0 2%; //newcss 0
  //box-shadow: 1px 2px 3px 3px rgba(202,202,202,.2);
  section {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateX(0) rotateY(0);
    pointer-events: none;
    transition: 100ms linear transform;
    overflow: hidden;
    background-position: center;
    pointer-events: none;
    box-shadow: 1px 2px 3px 3px rgba(202, 202, 202, 0.2);
  }
  .reflection-grid-cell {
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
  }

  @for $r from 1 to 11 {
    @for $c from 1 to 11 {
      .reflection-grid-cell-#{( ($r*10) + $c - 10)} {
        top: ($r * 10%)-10%;
        left: ($c * 10%)-10%;
      }
      .reflection-grid-cell-#{( ($r*10) + $c - 10)}:hover ~ section {
        transform: rotateX((($r * -5)+25deg)) rotateY((-25deg+ ($c * 5)));
        &:before {
          transform: translateY(25- (5% * $r));
        }
      }
    }
  }
}

.home-mainContainer {
  margin-top: 2%;
}

.loader.banner-loader {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 20%;
}

.bannerimages {
  background-position-y: 70%;
  // @media screen and (min-width: 320px) and (max-width: 1024px) {
  //   background-position-x: 10%;
  // }
}
