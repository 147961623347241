@media print {
  body .featureSection {
    display: none !important;
  }

  body .userActionOptions {
    display: none !important;
  }
  body .watsappIcon {
    display: none !important;
  }
  body,
  html,
  body .pushMarketingContainer {
    height: 100% !important;
    display: block;
    margin-top: 0 !important;
    header {
      position: relative;
      z-index: 2;
      -webkit-print-color-adjust: exact;
      margin: auto;
    }
    > div {
      page-break-inside: avoid;
    }
    section:last-child {
      button {
        display: none !important;
      }
    }
  }
  body .MobileBottomNavigationWrapper {
    display: none !important;
  }
  body .usvHeaderMobile {
    position: relative !important;
    background: none !important;
    margin-left: 5%;
  }
  body .usvHeaderMobile .mobileNavigation .toggleIconWrapper  {
    display: none !important;
  }
  body .userfeedback{
    display: none !important;
  }
}
