.allReq-gridViewMobile {
    width: 100%;

    .reqName {
        font-size: 16px;
    }
    .reqId {
        font-size: 14px;
    }

    .firstRowDraftMobile {
        width: 95%;
    }

    .reqDetailsCardMobile {
        position: relative;
        min-height: 135px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #88888840;
        border-radius: 10px;
        opacity: 1;
        padding: 10px;
        margin: 5% 0.5% 1% 1%;
        text-align: center;
        font-family: "Roboto";
        cursor: pointer;
    }
    .dateOfReqHeader {
        font-size: 16px;
        margin-top: 1em;
        font-weight: 500;
    }
    .dateOfReqHeader-viewReq {
        font-size: 16px;
        margin-top: 2em;
        font-weight: 500;
    }
}
.secondRowDraftMobile {
    text-align: left;
}
.thirdRowDraftMobile {
    display: flex;
    .draftButton {
        width: 6em;
        height: 2em;
        font-size: 14px;
        box-shadow: 0px 6px 20px #88888840;
        border: none;
        border-radius: 5px;
        opacity: 1;
        position: absolute;
        right: 10px;
        color: #fff;
        padding-top: 2px;
    }
}
