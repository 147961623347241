@import "../../shared";

.FilterReqList {
  margin-bottom: 1rem;
  display: flex;
  font-size: 0.82rem !important;
  cursor: pointer;
  justify-content: flex-end;
}

.reqDetailsCard {
  position: relative;
  width: 285px;
  min-height: 150px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #88888840;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  margin: 0.5% 0.5% 1% 1%;
  text-align: center;
  font-family: "Roboto";
  cursor: pointer;
  @media screen and (min-width: 1400px) {
    width: 18.5rem;
  }
  @media screen and (min-width: 1600px) {
    width: 21rem;
  }
  @media screen and (min-width: 1920px) {
    width: 25rem;
  }
}

.reqListContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-evenly;
}

.firstRow {
  display: flex;

  img {
    width: 55px !important;
    height: 55px !important;
  }

  .reqImg {
    border: 1px solid;
  }

  .reqTypeImgContainer {
    width: 40px;
    height: 40px;
    float: left;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 3px #ccc;
  }
}

.reqId {
  text-align: left;
  font: normal normal medium 0.82rem Roboto;
  font-size: 0.82rem;
  letter-spacing: 0px;
  color: #0f1c49;
  opacity: 0.5;
}

.reqName {
  text-align: left;
  font: 500 16/24px Roboto !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #272738;
  opacity: 1;
  // @media screen and (min-width: 1920px) {
  //   padding-left: 3%;
  // }
}

.secondRow {
  text-align: left;
  height: 2rem;

  span {
    font-size: 0.82rem;
    font-family: "Roboto";
  }
}

.thirdRow {
  display: flex;
  text-align: left;
  align-items: flex-end;

  div {
    width: 50%;
  }

  .dateOfReqRaised {
    font-size: 0.82rem;
    @media screen and(min-width:1920px) {
      font-size: 0.92rem;
    }
  }

  .timeOfReqRaised {
    font-size: 0.82rem;
    color: #828294;
  }

  button {
    float: right;
    width: 65%;
    height: 30px;
    color: #fff;
    outline: none;
    border: none;

    @media screen and (min-width: 1920px) {
      width: 55%;
    }
  }
}

.reqStatusBtn {
  font-size: 0.82rem;
  box-shadow: 0px 6px 20px #88888840;
  border-radius: 5px;
  opacity: 1;
}
.submittedReq,
.DraftReq {
  background: #0a358e 0% 0% no-repeat padding-box;
}

.inProgressReq {
  background: #e98724 0% 0% no-repeat padding-box;
}

.completedReq {
  background: #419268 0% 0% no-repeat padding-box;
}

.cancelledReq {
  background: #c50505 0% 0% no-repeat padding-box;
}

.concludedReq {
  background: #f7d4da 0% 0% no-repeat padding-box;
  color: #d93954 !important;
}

// LIST VIEW REQUEST DETAILS

.allReq-listView {
  width: 100%;
  color: #0f1c49;
}
.listView-allReq {
  width: 100%;
  margin: 20px auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #09577a29;
  border-radius: 10px !important;
  border: 1px solid #fff;
  opacity: 1;

  @media screen and (max-width: 1024px) {
    background: none;
    box-shadow: none;
    border-radius: none;
    border: none;
  }
}

.allReq-listView_myReq {
  .MuiSnackbar-root {
    // position: static !important;
    right: -90% !important;
    z-index: 10;
  }
}
.reqTitle-header {
  display: flex;
  font-size: 1.2rem;

  font-family: "Roboto";
}

.clickedReqDetails {
  width: 100%;
  background: #fff;
  .reqDetailDesc {
    font-size: 0.82rem;
    width: 50%;
    height: 3rem;
  }
}

.requestDetailsListView {
  display: flex;
}

.reqStatusDateContainer {
  font-size: 0.82rem;
  width: 95%;
  margin: 0 auto;
  display: flex;

  .reqStatusDate {
    width: 33%;
    justify-content: center;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      margin-left: 15px;
      font-size: 14px;
    }
  }
}

.wrapper {
  .MuiStepper-root {
    padding: 0.5rem;

    .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel {
      .Mui-disabled.MuiStepLabel-alternativeLabel {
        .MuiStepLabel-labelContainer span {
          color: rgba(0, 0, 0, 0.87);
          font-weight: 500;
        }
      }
    }

    .MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(2) {
      svg.MuiStepIcon-root.MuiStepIcon-active {
        color: rgba(0, 0, 0, 0.38);
        .MuiStepIcon-text {
          font-size: 0px;
        }
      }
      svg.MuiStepIcon-root.MuiStepIcon-completed {
        color: #ff8f43;
      }
    }

    .MuiStep-horizontal.MuiStep-alternativeLabel:last-child {
      svg.MuiSvgIcon-root.MuiStepIcon-root {
        .MuiStepIcon-text {
          font-size: 0px;
        }
      }
      svg.MuiStepIcon-root.MuiStepIcon-active {
        color: rgba(0, 0, 0, 0.38);
        .MuiStepIcon-text {
          font-size: 0px;
        }
      }
      svg.MuiStepIcon-root.MuiStepIcon-completed {
        color: green;
      }
    }
  }
}

.myReqList-mainContainer-myreq,
.myReqList-mainContainer {
  width: 90%;
  margin: 0 auto;
  .viewMyRequest-ReqImg {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    width: 3rem;
    height: 3rem;
    // min-height: 40px;
    float: left;

    // @media screen and (min-width:1920px) {
    //   background-size: 55% !important;
    //   width: 10%;
    // }
  }
  // .viewMyRequest-ReqImg.academicReqImage {
  //   width: 22%;
  //   @media screen and (min-width:"1920") {
  //     width: 16%;
  //   }
  // }
  .academicReqImage {
    background: url("../../assets/images/GroupLeft/leftAcademic.svg");
  }

  .patientAwarenessReqImg {
    background: url("../../assets/images/GroupLeft/leftPatientAwareness.svg");
  }
  .patientCareReqImg {
    background: url("../../assets/images/GroupLeft/leftPatientCare.svg");
  }
  .practiceEnhancement {
    background: url("../../assets/images/GroupLeft/leftPracticeEnhancement.svg");
  }
  .research {
    background: url("../../assets/images/GroupLeft/leftResearch.svg");
    // margin-right: 10px;
  }
}

.requestStatusTracker {
  display: none;
}

.enableStatusTracker {
  display: block;
}

.reqDetails-single {
  background: #fff;
  min-height: 20rem;
  width: 90%;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    background: none;
  }
  .reqStatusDate {
    width: 100%;
    .MuiBox-root {
      width: 33%;
      padding-left: 1%;
      text-align: center;
    }
    .reqStatusLiveTracker {
      .MuiBox-root {
        width: 100%;
      }
    }
  }
  .MuiSnackbar-anchorOriginBottomCenter {
    width: 80%;
    margin: 0 auto;
  }

  // .firstSection {
  //   width: 95%;
  //   margin: 0 auto;
  // }
}

.goBackToGridView {
  margin-top: 1%;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  label {
    margin-left: 1%;
    cursor: pointer;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
}

.firstSection {
  height: 5rem;
  padding-top: 1rem;
  display: flex;
}

.secondSection {
  @media only screen and (max-width: 1024px) {
    margin-top: 80px;
  }
}

.thirdSection {
  width: 100%; //85%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-evenly;

  .viewReqFilledVal {
    width: 30%;
    margin: 5px;
    padding: 5px;
    .titleHeader {
      text-align: left;
      font: normal normal normal 14px Roboto;
      letter-spacing: 0px;
      color: #09577a;
      margin-bottom: 5px;
    }

    .titleValue {
      text-align: left;
      font: normal normal medium 0.9rem Roboto;
      letter-spacing: 0px;
      color: #0f1c49;
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.servicetype {
  width: 90%;
  display: flex;
  margin-left: 1%;

  .reqTypeDetail {
    font-size: 0.82rem;
    width: 80%;
    @media only screen and (max-width: 1024px) {
      font-size: 15px;
    }
    .serviceName {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.requestId {
  font-weight: 600;
  font-size: 0.9rem;
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
    font-weight: normal;
    font-size: 14px;
  }
}

.showCancelOption {
  button {
    padding: 0 !important;
  }
}

.feedbackIcon {
  position: absolute;
  right: 5%;
  top: 1%;
  display: flex;
  justify-content: flex-end;
  .MuiSvgIcon-colorPrimary {
    color: #2d7beb !important;
  }
}

.cancelReqText {
  color: #c50505;
  text-align: left;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
  width: 30%;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    width: 70%;
  }
}
.feedback-mainContainer {
  display: flex;
  padding: 1rem;
  .leftContainer {
    padding: 1rem;
    background-color: #f2f9f9;
    h2 {
      color: #0a358e !important;
    }
    .feedbackImg img {
      width: 19rem;
      @media only screen and (max-width: 1024px) {
        width: 15rem;
      }
    }
  }
  .rightContainer {
    padding: 1rem;
    padding-left: 5%;

    .MuiInputBase-root,
    .MuiFormLabel-root {
      font-size: 0.9rem !important;
    }

    .MuiBox-root {
      margin-bottom: 2%;
    }
    .simple-dialog-title {
      font-size: 0.9rem !important;
    }
    legend {
      font-size: 0.9rem !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
.sendFeedBackBtn {
  width: 10rem;
  margin-top: 5% !important;
  color: #fff !important;
  // background-color: #0a358e;
  .MuiButton-label {
    text-transform: none;
  }
}

.feedBackDoneBtn {
  width: 10rem;
  margin: 0 auto !important;
  margin-bottom: 2rem !important;
  color: #fff !important;
  // background-color: #0a358e;
  .MuiButton-label {
    text-transform: none;
  }
}

.enablefeedBackBtn,
.feedBackDoneBtn {
  background-color: #0a358e !important;
  cursor: pointer;
}

.CancelReqYes {
  min-width: 20% !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 5px;
  text-transform: none !important;
  margin: 5%;
  color: #0f1c49 !important;
  box-shadow: none;
  &:hover {
    background: #09577a 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #09577a !important;
    opacity: 0.8;
  }
}

.CancelReqNo {
  background: #09577a 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border: 1px solid #09577a !important;
  min-width: 20% !important;
  border-radius: 5px;
  text-transform: none !important;
  margin: 5%;
  box-shadow: none;
}

.cancelBtnContainer {
  // margin: 0 auto !important;

  button {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.closeFeedBackDialog {
  float: right;
  cursor: pointer;
  margin: -10%;
  @media screen and (min-width: 1400px) {
    margin: -5%;
  }
}

.feedBack-ConfirmPopup {
  color: #0a358e !important;

  .MuiDialogContent-root {
    padding-top: 1.5rem;
  }

  .confirmFeedBackImg {
    margin: 0 auto;
    img {
      width: 24rem;
      height: 15rem;
      @media screen and (min-width: 1440px) {
        width: 26rem;
      }
    }
  }
  .feedback-thankyou {
    font-weight: 600;
    color: #0a358e !important;
    text-align: center;
    margin-top: 1rem;
  }
  .confirmFeedBackText {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  .feedBackDoneBtn {
    margin-top: 3rem !important;
  }
}

.confirmCancelText {
  margin-top: 1%;
  margin-left: 2%;
  color: #09577a;
}

.filterIcon {
  margin-right: 1rem;
  padding-top: 11px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }
}

.filterOptionContainer {
  background: #fff;
  box-shadow: 0px 10px 26px #00000033;
  border-radius: 5px;
  position: absolute;
  padding: 5px;
  z-index: 2;
  width: 10% !important;
  top: 15%;
  right: -95%;
  display: none;
  div {
    height: 1.5rem;
    margin: 5px;
  }
}

.categoryFilterContainer,
.statusFilterContainer {
  margin-left: 2%;
  margin-right: 2%;
  label + .MuiInput-formControl {
    margin-top: 0.82rem !important;
  }
  .MuiSelect-select.MuiSelect-select {
    @media screen and (min-width: 320px) and (max-width: 600px) {
      width: 6rem;
    }
    width: 8rem;
    font-size: 0.82rem !important;
  }
  .MuiFormLabel-root {
    font-size: 0.82rem !important;
  }
}

.filterByOptions {
  display: flex !important;
  justify-content: flex-end !important;
}

.viewSelectedRequest-ReqImg {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
  width: 3em;
  height: 3em;
  // min-height: 40px;
  float: left;
}

.CancelSelectedReqPopup {
  .MuiDialog-paperScrollPaper {
    width: 50%;
    @media screen and (min-width: 1600px) {
      width: 35%;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .MuiSvgIcon-root {
    fill: #09577a;
  }
  .MuiDialogContent-root {
    width: 70%;
    margin: 0 auto;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    .MuiTextField-root {
      margin-top: 5%;
    }
  }
}

.feedBack-mainPopup {
  .MuiDialog-paper {
    width: 75%;
  }
}

.displayAttachment {
  a {
    padding-left: 10px;
    font-size: 13px;
  }
}

.attachments {
  text-align: left;
  font: normal normal normal 14px Roboto;
  letter-spacing: 0;
  color: #09577a;
  margin-bottom: 5px;
}

.requiredFeedbckQuestion {
  color: red;
}
