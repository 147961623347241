@import "../../../shared.scss";

.createReqDropdown {
  width: 25em;
  .MuiSelect-root.MuiSelect-select {
    width: 90%;
    padding: 0.1rem;
    padding-top: 0.3rem;
    padding-left: 1rem;
    text-align: left;
    min-height: 1.5rem;
    background-color: none !important;
    font-size: 0.9rem;
    color: $formFieldColor;
  }
}

.audienceTypeDD {
  em {
    font-style: normal;
  }
}

.userFilledEntity {
  #datetime-local-label {
    display: none;
  }
}

.MuiInputBase-root:hover {
  outline: none !important;
}

.MuiInput-underline {
  :hover {
    border-bottom: none !important;
  }
  :after {
    border-bottom: none !important;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #09577a !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-color: inherit !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: none !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #eee !important;
  border-width: 1px;
}

.MuiBadge-anchorOriginTopRightRectangle {
  top: 0.7rem !important;
  right: 1rem !important;
}

.RequestSubtypeDD {
  width: 21em;
  margin: 0 auto;

  @media screen and (min-width: 1920px) {
    margin-top: 2%;
  }
}

.requestBuckets:hover {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.08),
    0px 5px 5px 1px rgba(0, 0, 0, 0.08), 0px 3px 5px 5px rgba(0, 0, 0, 0.08) !important;

  @media screen and (max-width: 1024px) {
    box-shadow: none !important;
  }
}

.selectRequestTypeBucket {
  width: 17em;
}

.disclaimerContainer {
  padding-top: 2%;

  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
  .MuiTypography-root {
    font-size: 0.8em !important;
  }
  .sampleDisclaimerText {
    input {
      width: auto;
    }
  }
  .mandatoryCheckbox {
    color: red;
  }
  .disclaimerNote {
    color: red;
    padding-left: 10%;
  }
  @media screen and (max-width: 1024px) {
    text-align: left !important;
    font-size: 0.7em !important;
  }
}

.addressCheckbox {
  text-align: left !important;
  padding-left: 10%;
}

.sessiontype .MuiListItemText-root span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sampleBrandNameDropdown {
  // width: 490px;
  // @media screen and (max-width: 1024px) {
  //   width: 100%;
  // }
  .MuiAutocomplete-popperDisablePortal {
    top: 100%;
  }
}
.MuiAutocomplete-listbox {
  font-size: 0.9rem;
}
.MuiAutocomplete-endAdornment
  .MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
  right: 1em !important;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input:first-child {
  min-width: 0 !important;
  z-index: 1;
}
.MuiAutocomplete-root
  .Mui-focused
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-inputFocused {
  padding: 0 !important;
}
.hidePaddingAutoCompReq
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input {
  padding: 0 !important;
}
.hidePaddingAutoCompReq .MuiInputBase-adornedStart {
  width: 100% !important;
  // border: 1px solid #eeeeee;
  padding: 5px 0px;
  color: #000;
  z-index: 1;
}

.arambhProgramType {
  @media screen and (max-width: 1024px) {
    margin-top: 30% !important;
  }
}
.samplebrandnameList {
  width: 28em;
  @media screen and (max-width: 1024px) {
    margin-top: 25em;
  }
}
