.faq-mainContainer {
  // background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #eeeeee;
  border-radius: 4px;
  opacity: 1;
  width: 60%;
  margin: 0 auto;
  white-space: pre-line;

  @media only screen and (max-width: 1024px) {
    margin-top: 6em !important;
    width: 80%;
  }

  .MuiAccordion-root {
    margin-top: 2%;
    box-shadow: none !important;
    border: 1px solid #eeeeee;

    &:before {
      background: none !important;
    }
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    background: #eeeeee 0% 0% no-repeat padding-box;

    border: 1px solid #eeeeee;
    border-radius: 4px;
    opacity: 1;
    min-height: 3rem;
    max-height: 3rem;
  }

  .MuiTypography-root {
    font-size: 0.82rem;
    @media only screen and (max-width: 1024px) {
      font-size: 11px;
    }
  }
}

.titleSection {
  display: flex;
  height: 4rem;
  background: transparent linear-gradient(94deg, #0a358e 0%, #53bff9 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headingPage {
    justify-content: flex-end;
  }

  h2 {
    color: #fff !important;
    padding-top: 2%;

    @media screen and (min-width: 1600px) {
      margin-right: 4%;
    }
    @media screen and (min-width: 1600px) {
      margin-right: 10%;
    }
    @media screen and (min-width: 1600px) {
      margin-right: 10%;
    }
    @media screen and (min-width: 1920px) {
      margin-right: 18%;
    }
  }

  .faqImgContainer {
    margin-right: 45%;
    @media screen and (min-width: 1400px) {
      margin-right: 35%;
    }
  }
  img {
    display: none;
    // position: absolute;
    //z-index: 2;
    width: 8rem;
    //  top: 7%;
    //  left: 60%;
    margin-bottom: 37%;
    // @media screen and (min-width: 1400px) {
    //   margin-bottom: 37%;
    // }
    // @media screen and (min-width: 1590px) {
    //   top: 6%;;
    // }
    // @media screen and (min-width: 1920px) {
    //   top: 6%;;
    // }
  }
}

.faq-rootContainer {
  margin-top: 3%;
}

.mobile-FAQHeader {
  padding-left: 2em;
  color: #295788;
}

.faqToHome {
  margin-left: 5px;
}

.goToHomeFaq {
  cursor: pointer;
}
