.usvHeaderMobile{
    filter: drop-shadow(0px 3px 15px #09577A1A);
    padding: 15px 10px;
    min-height: 9vh;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #fff;
    z-index: 999;
    .usvIconDivMobile{
        img{
            width:150px;
            height:45px;
            margin-left:30px;
        }
    }
}