.usvHeaderMobile{
    // background-color: #fff;
    filter: drop-shadow(0px 3px 15px #09577A1A);
    padding: 15px 10px;
    min-height: 8vh;
.mobileNavigation{
    span{
        img{
            width:50%;
        }
    }

    .toggleIconWrapper{
        padding-top:10px;
    }
    .back-arrow{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header-Title{
        h1{
        color:#295788;
        font-weight: 500;
        margin-top:10px;
        }
    }
}

}