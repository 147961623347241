.req-mainContainer {
  // background: #fcfbfc 0% 0% no-repeat padding-box;
  // background: url("../../assets/images/BG.svg") no-repeat center fixed;
  // min-height: calc(100vh - 1rem - 50px);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  margin-top: 1.5%;
  .left-intersection {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .right-intersection {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  // @media screen and (min-width:"1920px") {
  //   margin-top: 2.5%;
  // }

  header {
    width: 90%;
    margin: 0 auto;
    background-color: #fcfbfc !important;
    box-shadow: none !important;
    border-bottom: 1px solid #e9e9e9 !important;
    padding-left: 1rem;

    .MuiTabs-indicator {
      background: #09577a 0% 0% no-repeat padding-box !important;
      border-radius: 5px;
    }

    .MuiButtonBase-root {
      font-size: 0.8rem !important;
      font-family: "Roboto";
    }

    .Mui-selected {
      font-size: 0.9rem !important;
    }

    .MuiTabs-root,
    .MuiTab-root {
      min-height: 0px !important;
    }
  }

  .categoryOptionContent-service article {
    // background: #ffffff 0% 0% no-repeat padding-box;

    border: none;
    border-radius: 1rem;
    min-height: 7rem;
    width: 16%;
    // transition: all 500ms ease 0s;
    padding-top: 1.2rem;
    box-shadow: 0px 10px 10px #0000000f;
  }
}

.activeDraftCont {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c50505 0% 0% no-repeat padding-box;
  color: #fff;
  font: normal normal normal 14px Poppins;
  text-align: center;
  position: absolute;
  left: 37.5%;
  padding-top: 2px;
}

//WALLPAPER
.createReq-mainContainer {
  // background: url('../../assets/images/reqBg.svg') no-repeat center fixed;
  // height: 100%;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;

  .categoryOptionContent-service section {
    width: 16%;
    height: 7rem;
    background: #09577a;
    position: absolute;
    margin: 2% 2% 2% 2%;
    border-radius: 20px;
  }

  .categoryOptionContent-service {
    width: 68%;
    margin: 0 auto;
    // height: 11em !important;

    @media all and (max-width: 1024px) and (max-height: 1024px) and (orientation: landscape) {
      width: 88%;
    }

    article {
      margin: 2% 2% 2% 2%;
    }

    @media screen and (min-width: 1440px) {
      width: 63%;
    }

    /* (1600x900) HD+ Display */
    @media screen and (min-width: 1600px) {
      width: 57%;
    }

    /* (1920x1080) Full HD Display */
    @media screen and (min-width: 1920px) {
      width: 50%;
      margin-top: 2%;
      margin-bottom: 2%;
    }
  }
}

.academicContent {
  .reqLogo {
    background-image: url("../../assets/images/academic.svg");
    background-repeat: no-repeat;
  }
  img {
    width: 25% !important;
  }
}

.whiteText {
  background: #09577a !important;
  box-shadow: 0px 5px 20px #00000066 !important;
  border: none !important;
  // border-radius: 0 !important;

  .reqTitle {
    color: #fff !important;
  }

  .reqLogo {
    background-image: url("../../assets/images/academicWhite.svg");
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 1024px) {
    box-shadow: none !important;
  }
}

.reqCategoryContentContainer {
  min-height: calc(52vh + 68px);
  position: relative;
  @media all and (min-width: 1621px) and (max-width: 1920px) {
    min-height: calc(52vh + 28px);
  }

  //box-shadow: 0px 30px 60px #09577a29;
  // margin-bottom: 1rem;
  // max-height: 30rem;
}
.otherScreeningTopic-enabled {
  min-height: calc(65vh + 28px);
}
.selectedRequestContent {
  width: 80%;
  margin: 0 0 0 0;
  margin: 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #09577a29;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  font-size: 14px;
  font-family: "Roboto";

  .MuiSelect-select:focus {
    background-color: white !important;
  }
}

.userFilledEntity {
  height: 3.5rem;

  .MuiFormLabel-root.Mui-error {
    color: inherit;
  }

  input,
  textarea {
    width: 24.5rem;
    padding-left: 0.82rem;
    font-size: 0.9rem;
    color: #0f1c49 !important;

    :focus {
      outline: none;
    }
  }

  // .MuiSelect-selectMenu {
  //   color: #0f1c49 !important;
  // }

  .MuiInputLabel-formControl {
    font-size: 0.9rem;
    padding-left: 1rem;
  }
}

.mandatoryTab,
.incompleteDetails {
  color: red;
}

.reqTitle {
  text-align: center;
  font: normal normal normal 12px Roboto;
  letter-spacing: 0px;
  letter-spacing: 0px;
  margin-top: 0.82rem;
}

.categoryOptionContent-service article img {
  width: 22%;
  height: 32px;
}

.reqFormHeader {
  font: normal normal normal 14px Roboto;
  letter-spacing: 0px;
  color: #878da4;
}

.submitRequestBtn {
  width: 10% !important;
  height: 40px;
  background: #09577a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px;
  font: normal normal medium 16px Roboto;
  letter-spacing: 0px;
  color: #ffffff !important;
  margin: 0 auto;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  text-transform: none !important;
}

// .showAnimateOpacity{
//   transition: 200ms all ;
//   opacity: 1;
// }

// .hideAnimateOpacity{
//   transition: 200ms all ;
//   opacity: 0;
// }

.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: red !important;
}

li.MuiListItem-root.MuiMenuItem-root,
.MuiListItemText-primary {
  font-size: 0.9rem !important;
}

.hcpCheckbox {
  .MuiSvgIcon-root {
    width: 75%;
  }
}

.form-fieldsContainer {
  min-height: 10rem !important;
}

.reqActionBtnContainer {
  margin-top: 1%;
  position: absolute;
  width: 100%;
  bottom: 5px;
  display: flex;
  left: 40%;

  .MuiCircularProgress-root {
    color: #fff !important;
  }

  .saveAsDraft {
    width: 10%;
    margin-left: 2%;
    color: #707070;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: none;
    &:hover {
      background: #09577a 0% 0% no-repeat padding-box !important;
      color: #fff !important;
      border: 1px solid #09577a !important;
      opacity: 0.8;
    }
  }
}

.draftBadgeCount {
  right: 13.5%;
  @media screen and (min-width: 1440px) {
    right: 11.5%;
  }
  @media screen and (min-width: 1600px) {
    right: 10.5%;
  }
  @media screen and (min-width: 1920px) {
    right: 9%;
  }
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    height: 80%;
    right: 10%;
    width: 10%;
  }
}

.sampleNotificationText{
    text-align: center;
    font-size: 12px;
  }