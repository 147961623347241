.pushMarketingContainer {
  width: 90%;
  margin: auto;
  height: 85vh;
  overflow: auto;
  header {
    height: 50px;
    background: #09577a;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: "Roboto";
  }
  hr {
    height: 5px;
    background: #09577a;
    margin: 0;
  }
  div {
    section {
      // display: flex;
      width: 100%;
      // background: #e7e7e7;
      img {
        width: 100%;
      }
    }
  }
  section:last-child {
    background: white;
    padding-left: 1%;
    margin-top: 1%;
    button {
      background: #09577a;
      color: white;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      padding: 0.25rem 0.8rem;
      margin-bottom: 1rem;
      @media screen and (max-width: 1024px) {
        font-size: 0.82rem;
      }
    }
  }
}

.actionButton {
  width: 97%;
  display: flex;
  justify-content: start;

  button {
    width: 10em;
  }
}
.userinputcontainer {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .userfeedback {
    width: 70%;
    margin-left: 2%;
    border: 2px solid #ced4da;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .submitFeedback {
    margin-left: 5%;
    margin-right: 1%;
    width: 10%;
    @media screen and (max-width: 1024px) {
      width: 50%;
      margin: 2% 25% 1% 25%;
    }
  }
  .votebutton {
    padding: 20px;
    width: 30%;
    margin-left: 3%;
    display: inline-block;
    background: #09577a;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    padding: 0.25rem 0.8rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 1024px) {
      width: 30%;
      margin-left: 3%;
      display: inline-block;
      margin-right: 0;
      font-size: 0.82rem;
    }
  }
}
.firstcontainer {
  width: 30%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.second-container {
  width: 70%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.pushMarketingMobile {
  margin-top: 10vh;
  height: 75vh;
}

.mobileSubmitFeedbackBanner {
  margin-bottom: 4em;
}
.articleIndex {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  display: block !important;
  color: #09577a;
  text-decoration: underline;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
}

.images-container {
  .imgSrc {
    max-width: 100%;
  }
}
