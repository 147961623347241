@import "../../shared.scss";
@import "../Login/Login.scss";
#verifyDetails {
  padding: 30px 40px 10px 15px;
  @media screen and (min-width: 1367px) and (max-height: 1560px) {
    padding: 30px 140px 10px 15px;
  }
  @media screen and (min-width: 1561px) and (max-height: 1920px) {
    padding: 30px 300px 10px 15px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    color: $titletextColor;
    font-family: $fontFamily;
    padding-left: 1rem;
  }

  p {
    font-size: 15px;
    font-family: $fontFamily;
    font-weight: 300;
  }


  .resendOtpSec {
    font-size: 0.9rem;
    margin: 2%;
    padding:2%;
  }

  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
    margin-right: 6rem;
  }
  @media screen and (min-width: 1366px) and (min-height: 1024px) {
    margin-right: 6rem;
  }
}

.resendOTPLink{
  cursor: pointer;
}                              

.incorrectOTPMsg{
  color: red;
}

.otpContainer {
  margin: 5% auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 1360px)and (max-width: 1400px){
    margin: 0% 0%;
    flex-direction: row;
    justify-content: left;
  }


}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 1rem 1rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
  color: #000000D9;
  font-weight: bold;
  border: 0.20000000298023224px solid #707070;
}

#otp-container{
  #logo{
    margin-right: 6.5rem;
    @media screen and (max-height: 1024px) and (min-height: 1024px) and (max-width: 1366px) {
      margin-right: 10rem;
    }
    @media screen and (min-width: 1366px) and (min-height: 1024px) {
      margin-right: 12rem;
    }
  }
  .ringSection-sec{
    top: 25%;
    left: 23%;
  }
}
