.hcpAddressContainer {
  display: block !important;
}
.deleteShippingAddress {
  float: right;
}
.addNewAdrressForm {
  width: 90%;
  margin: 0 auto;
}

.addNewAdrressFormField {
  div:first-of-type {
    width: 100%;
  }
}

.submitNewShippingaddDetails {
  width: 50%;
  font-size: 14px !important;
  text-transform: initial !important;
  background-color: #09577a !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px !important;
  color: #fff !important;
  margin-left: 25% !important;
}
.CloseDialog {
  position: absolute;
  right: 0;
}
.MuiAutocomplete-endAdornment {
  button {
    position: absolute;
    right: 0;
  }
}
.addressNotification {
  font-size: 11px;
  margin-left: 5%;
  color: red;
}

.addNewAdrressState {
  .Mui-disabled input {
    color: #0f1c49;
  }
}

.MuiAutocomplete-inputRoot,
.MuiInput-underline {
  input {
    color: #0f1c49;
  }
}
