
.dateOfReqHeader {
    border-bottom: 1px solid #E3E8F1;
    color: #0a358e;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
  }
  
  .allReq-gridViewMobile {
    width: 100%;

    .reqName {
        font-size: 16px;
    }
    .reqId {
        font-size: 14px;
    }

    .firstRowDraftMobile {
        width: 95%;
    }

    .reqDetailsCardMobile {
        position: relative;
        min-height: 150px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #88888840;
        border-radius: 10px;
        opacity: 1;
        padding: 15px;
        margin: 5% 0.5% 1% 1%;
        text-align: center;
        font-family: "Roboto";
        cursor: pointer;
    }
    .dateOfReqHeader{
font-size: 18px;
    }
}
.secondRowDraftMobile {
    text-align: left;
    margin-top: 5px;
}
.thirdRowDraftMobile {
    display: flex;
    .reqStatusBtn {
        width: 6em;
        height: 2em;
        font-size: 14px;
        box-shadow: 0px 6px 20px #88888840;
        border: none;
        border-radius: 5px;
        opacity: 1;
        position: absolute;
        right: 10px;
        color: #fff;
    }
}
 
  


