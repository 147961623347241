.MuiDrawer-paper {
    min-width: 80%;
}

.mobileToggleWrapper {
    width: 100%;
    .usvIconDivMobile {
        height: 140px;
        width: 100%;
        background: rgba(198, 206, 221, 0.2);
        display: flex;
        padding: 5% 5% 5% 10%;
        flex-direction: row;
        .doctorProfileImgtoggle {
            width: 30%;
            height: 100%;
            padding-top: 30px;

            @media screen and (min-width: 600px) and (max-width: 1024px) {
                padding-top: 0px;
            }
            .profileImgDivContainer {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                opacity: 1;
                border: 0.5px solid #c6cedd;
            }
        }
        .hcpProfileDesctoggle {
            width: 70%;
            height: 100%;
            padding-top: 30px;
            @media screen and (min-width: 600px) and (max-width: 1024px) {
                padding-top: 0px;
            }
            .hcpNametoggle {
                h2 {
                    color: #09577a !important;
                }
                h4 {
                    text-align: left;
                    font-size: 14px !important;
                    font-weight: 700;
                    font-family: Roboto;
                    letter-spacing: 0px;
                    color: #000000 !important;
                    opacity: 1;
                }
            }
            .hcpSpecstoggle {
                text-align: left;
                font: normal normal normal 12px/14px Roboto;
                letter-spacing: 0px;
                color: #000000 !important;
                opacity: 1;
            }
        }
    }
    .copyrights {
        position: absolute;
        bottom: 10px;
        height: 50px;
        padding: 15px 20px;
        width: 80%;
        border-top: 1px solid #e1e1e1;
        text-align: left;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.5;
        font-size: 12px;
        @media screen and (orientation: landscape) {
            color: red;
        }
    }
    .copyrightslandscape {
        position: inherit;
        bottom: 10px;
        height: 50px;
        padding: 15px 20px;
        width: 80%;
        border-top: 1px solid #e1e1e1;
        text-align: left;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.5;
        font-size: 12px;
    }
    .logoutMobileText {
        margin: 1.2em;

        @media screen and (orientation:portrait) {
            position: absolute;
            bottom: 8%;
        }
        .logout-mobile {
            margin: 10px;
            color: #09577a;
            font-size: 0.95rem;
            font-weight: 700;
            padding-left: 0px;
        }
    }
    .toggle-list {
        margin: 10px 20px;
        .MuiListItemIcon-root {
            min-width: 40px;
        }
        div {
            padding: 0px !important;

            a {
                width: 100%;
                padding: 5px 10px;
                div {
                    span {
                        text-align: left;
                        font: normal normal normal 16px/19px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        padding-left: 25px;
                        font-weight: 700;
                    }
                }
            }
        }
        .MuiListItem-gutters {
            width: 80%;
            color: #000 !important;
            margin: 10px auto;
            border-bottom: 0.2px solid #ededed;
            div {
                span {
                    color: #000;
                    font-size: 0.95rem !important;
                    font-weight: 700;
                }
            }

            &:hover {
                background-color: #e1dfdd !important;
                opacity: 50%;
                border-radius: 10px !important;
            }
            &:active {
                background-color: #e1dfdd !important;
                opacity: 50%;
                border-radius: 10px !important;
            }
        }
        // .MuiButtonBase-root{
        //     &:last-of-type {
        //         position: fixed;
        //         bottom: 60px;
        //         left: 20px;
        //         width: 40%;
        //         border-bottom: none;
        //         .logout-mobile{
        //             color:#09577A !important;
        //             font-size: 0.95rem !important;
        //             font-weight: 700;
        //             padding-left: 0px;
        //             padding-right:0px;
        //                 div{
        //                     span{
        //                         color:#09577A !important;
        //                         font-size: 0.95rem !important;
        //                         font-weight: 700;
        //                         padding-left:0px;
        //                     }
        //                 }
        //             }

        //     }
        // }
    }
}

@media (orientation: landscape) {
    body {
        background-color: red;
    }
}
