@media screen and (min-width: 320px) and (max-width: 1024px) {
.notification-mainContainer-mobile{
   .notificationListContainer {
    display: flex;
    width: 100%;
    padding: 10px 30px 10px 30px;
    background-color: #fcfcfc;
    border-bottom: 1px solid #09577A1A;
    // .notificationListContainerRead {
    //     background-color: #fcfcfc; 
    // }
    //filter: drop-shadow(0px 3px 15px #09577A1A);
}
    .notification-list{
        .article {
            width: 100%;
            min-height: 4.5rem;
            padding: 20px 30px;
        }
      
    }
    .feedback-mainContainer{
        padding:50px 20px;
    }
}
.closeFeedBackDialog{
    float:right;
    position:absolute;
    right:0px;
    top:0px;
    margin:0px;
}

.feedBack-ConfirmPopup .feedBackDoneBtn {
    margin-top: 3rem !important;
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 20px auto !important;
    padding: 15px !important;
    background-color: #09577A !important;
}


.feedBack-mainPopup-mobile{
    margin:40px 0px;
    .feedbackImg{
        img{
            width:50% !important;
        }
    }
}
.confirmFeedBackImg{
    img{
        width:100% !important;
    }
}
.feedback-mainContainer {
    display: flex;
    width: 100%;
    margin: 0;
    padding:0px;
.leftContainer{
        padding:0 !important;
        h2{
            font-size: 1.2rem;
            padding: 5px;
        }
    }
.rightContainer{
    width:100%;
    padding: 0px;
    margin-top:10px;
    h4{
        font-size:1rem;
    }
    legend{
        font-size:0.8rem;
    }
    .MuiOutlinedInput-multiline{
        padding:5px;
    }
    .MuiButtonBase-root{
        display: flex ;
        justify-content: center;
        width: 100%;
        margin: 20px auto !important;
        padding: 15px !important;
        background-color: #09577A !important;
    }
}
}
}