/*
 * Logout css File
*/
.logOut_popup{

    .MuiDialogTitle-root{
       h2{
        color: #09577A;
       } 
    }
    .MuiDialogContent-root p{
        color: #09577A;
        // padding-left: 6%; 
        font-size: 0.9rem; 
        width: 85%;
        margin: 0 auto; 
        @media screen and (min-width:"1920px") {
            width: 90%;  
        }
    }
}

.logoutBtn {
    min-width: 20% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E1E1E1 !important;
    border-radius: 5px;
    text-transform: none !important;
    margin:5% 0 5% 5% !important;
    color: #0F1C49;
    box-shadow: none;

  &:hover{
    background: #09577a 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #09577a !important;
    opacity: .8;
}
}
.cancelBtn{
    background: #09577a 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #09577a !important;
    min-width: 20% !important;
    border-radius: 5px;
    text-transform: none !important;
    margin:5% 0 5% 0 !important;
    box-shadow: none;
}