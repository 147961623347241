@import "../../shared.scss";

.categoryOption-mainContainer {
  overflow: hidden;
}
.categoryOptionContent-service {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  p {
    margin-top: 8%;
    font-size: 13px;
    font-family: "Roboto";
    white-space: pre-line;
    width: 80%;
    margin: 8% auto 0 auto;
  }
  section {
    width: 16%;
    height: 100px;
    background: #09577a;
    position: absolute;
    // margin: 0 3% 0 3%;
    margin-left: 2%;
    border-radius: 10px;
    // box-shadow: 0px 5px 15px #00000066;
    // opacity: 0.6;
    // backdrop-filter: blur(50px);
    // -webkit-backdrop-filter: blur(50px);
    @media screen and (min-width: "1920px") {
      width: 15%;
    }
  }
  article {
    cursor: pointer;
  }
}
.reqTypeDetails {
  height: 2.5em;
  width: 30em;
  text-align: center;
  margin: 0 auto;
  border-bottom: 1px solid #f6f6f6;
  padding-top: 5px;
  font-size: 13px;
  font-family: "Roboto";
  // position: relative;
  margin-top: 0.5rem;
  cursor: pointer;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
    fill: #6796ab;
    margin-left: 2%;
  }

  label {
    cursor: pointer;
  }
}

div.reqTypeDetails:last-child {
  border-bottom: none !important;
}

.typeOfReq-mainContainer {
  position: relative;
}

.typeOfReq-mainContainer,
.reqCompleteList {
  margin: 2% 0 2% 0;
  opacity: 1;
  transition: all 500ms;
}

.serviceInfo {
  width: 13px;
  height: 13px;
  background: #6796ab;
  color: #fff;
  margin-left: 2%;
  border-radius: 50%;
  font-size: 0.5rem;
  font-family: "Roboto";
  cursor: pointer;
}

.hcpRequestTracking {
  display: flex;
  padding-bottom: 6px;
  border-bottom: 1px solid #f6f6f6;
  font-size: 13px;
  font-family: "Roboto";
  width: 94%;
  margin: 0 auto;

  .hcpReqName {
    width: 75%;
  }
  .hcpReqStatus {
    width: 25%;
  }
  & > section:first-child {
    color: #09577a;
    text-align: right;
    width: 10%;
    //padding: 0.5rem 0;
    & > div:first-child {
      font-size: 13px;
      font-weight: 500;
    }
    & > div:nth-child(2) {
      font-size: 15px;
      font-weight: 600;
    }
    & > div:nth-child(3) {
      font-size: 13px;
      font-weight: 500;
    }
  }
  & > section:last-child {
    width: 90%;

    & > article {
      display: flex;
      height: 30px;
      margin: 0 0 0 0;
      & > div:first-child {
        width: 40%;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > div:nth-child(2) {
        width: 30%;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > div:nth-child(3) {
        width: 30%;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > div:nth-child(3) img {
        margin-right: 0.5rem;
      }
    }
  }
}
.hcpEventsHome {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 13px;
  font-family: "Roboto";
  width: 94%;
  margin: 0 auto;

  .hcpEventName {
    width: 75%;
  }
  .hcpReqStatus {
    width: 25%;
  }
  & > section:first-child {
    color: #09577a;
    text-align: right;
    width: 10%;
    padding: 0 0;
    & > div:first-child {
      font-size: 13px;
      font-weight: 500;
    }
    & > div:nth-child(2) {
      font-size: 15px;
      font-weight: 600;
    }
    & > div:nth-child(3) {
      font-size: 13px;
      font-weight: 500;
    }
  }
  & > section:last-child {
    width: 90%;
    & > article {
      display: flex;
      height: 30px;
      margin: 0 0 0 0;
      & > div:first-child {
        width: 35%;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
      }
      & > div:nth-child(2) {
        width: 25%;
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      & > div:nth-child(3),
      div:nth-child(4) {
        width: 20%;
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      & > div:nth-child(3) img {
        margin-right: 1rem;
      }
    }
  }
}

.pendingReq {
  color: $pendingStatus;
}

.submittedReq {
  color: $submitStatus;
}
.serviceImgClass {
  width: 77.609px;
  height: 45.108px;
}
.requestImgClass {
  width: 60px;
  height: 25px;
}
.requestImgBackgroud {
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 50%;
  // box-shadow: 1px 3px 8px 3px #ccc;
}
.academics {
  background: url("../../assets/images/academic.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
}
.patientCare {
  background: url("../../assets/images/patient_awareness_support.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
}
.completedReq {
  color: $completedStatus;
}

.serviceInfoDesc {
  height: 70px;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  width: 30%;
  text-align: left;
  padding: 1% 1%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 11px 13px #00000029;
  border-radius: 8px;
  opacity: 1;

  h5 {
    font-size: 13px;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: #878da4;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.showOpacity {
  opacity: 1;
  transition: all 500ms;
}
.topPicksHome img {
  width: 16%;
  margin: 2%;
  @media screen and (min-width: 1360px) and (max-width: 1400px) {
    margin: 0;
  }
}
.topPicksHome img:first-child {
  margin-left: 0;
}
.topPicksHome img:last-child {
  margin-right: 0;
}

.user-activityPanel .allService-reqContainer {
  width: 95%;
  margin: 0 auto;
}
.closeBtnHomePage {
  position: absolute;
  right: 0;
  top: 25%;
  color: #09577a;
  cursor: pointer;
}
.reqtypeName {
  cursor: pointer !important;
}
.seeMore {
  cursor: pointer;
  // height: 3rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #09577a;
  font-weight: 600;
  position: absolute;
  bottom: 10px;
}



.panelRequestConatiner,
.panelEventsConatiner {
  height: 21rem;
}



.eventContentDetail,.requestContentDetail{
display: flex;
flex-direction: column;
justify-content: center;
}

.noUpcomingEvents{
  text-align: center;
  margin-top: 2%;
  font-size: 14PX;
}

.sampleNotificationText-HomePage{
   font-size: 12px;
   text-align: center;
    margin-top: 1%;
   }