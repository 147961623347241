// html{
//   height: calc(100vh - calc(100vh - 100%))
// }

// body,#root,main{
//   height: 100%;
// }

.MobileWraper {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100%;
  .mobile-topImage {
    position: fixed;
    top: 0;
    left: 0;
  }
  .mobile-bottomLeftImage {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .mobile-bottomRightImage {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
