@import "../../shared";
.usvHeader {
  height: 50px;
  width: 100%;
  display: flex;
  padding: 1% 0 0 0;
  //margin: 15px 0 20px 0;

  @media screen and (min-width: "1620px") {
    height: 70px;
  }
}
.iconNSearchSection {
  width: 30%;
  height: 100%;
  display: flex;
  padding: 0 1rem 0 5rem;
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    padding: 0 0 0 1em;

}
}
.featureSection {
  width: 40%;
  height: 100%;
  display: flex;
  font-size: 13px;
  justify-content: flex-end;
  padding-right: 6%;
  align-items: center;
  font-family: "Roboto";
  @media screen and (min-width:"1620px") {
    padding-right: 8.5%;
  }
}
.featureSection article {
  padding: 0 1rem;
  cursor: pointer;
  a {
    text-decoration: none;
    color: #000;
    padding: 5px;
    &.active {
      border-bottom: 2px solid #09577a;
      color: #09577a;
      font-weight: 700;
    }
  }
}

.userActionOptions {
  width: 40%;
  height: 100%;
  display: flex;
  font-size: 13px;
  justify-content: flex-end;
  padding-right: 7%;
  align-items: center;
  font-family: "Roboto";
  @media screen and (min-width: "1440px") {
    padding-right: 5%;
  }

  article {
    padding: 0 1rem;
    cursor: pointer;
  }
  a {
    text-decoration: none !important;

    .MuiAvatar-root {
      width: 30px !important;
      height: 30px !important;
    }
  }
  a:hover {
    border-bottom: none;
  }

  .MuiButton-root {
    color: #fff;
  }

  .MuiButton-label {
    font-size: 0.75rem;
  }
}

.usvIconDiv {
  width: 20%;
  height: 100%;
  display: flex;
  // justify-content: flex-end;
  // align-items: center;
  img {
    width: 10rem;
    @media screen and (min-width: "1620px") {
      width: 16em;
    }
  }
}
.searchDiv {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $searchIcon;
  border-radius: 24px;
  padding: 0 10px;
  margin-left: 2rem;
}

.searchDiv .MuiInput-underline:before {
  border-bottom: 0;
}
.searchDiv .MuiInput-underline:after {
  border-bottom: 0;
}
.searchDiv .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}
.searchIcon {
  font-size: $searchIconFont !important;
  color: $searchIcon;
}
.searchDiv input::placeholder {
  color: $searchIcon;
  font-size: $textFieldFontSize;
}
.searchDiv input {
  color: $searchIcon;
  font-size: $textFieldFontSize;
}
.searchContentClass {
  width: 88%;
  padding: 0 5px 0 5px;
}

.navigationReqBtn {
  width: 8rem;
  font-size: 14px !important;
  text-transform: initial !important;
  background-color: #09577a !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px !important;

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.notification-mainContainer {
  width: 30%;
  height: 50%;
  float: right;
  position: absolute;
  height: 88vh;
   overflow: scroll;
  right: 10%;
  // background: transparent linear-gradient(115deg, #fff, hsla(38, 10%, 100%, 0.6) 50%, hsla(0, 0%, 100%, -0.298039)) 0 0
  //   no-repeat padding-box;
  background-color: #fff;
  box-shadow: 0px 10px 26px #00000033;
  opacity: 1;
  z-index: 10;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  border-radius: 10px;
  display: none;

  @media screen and (min-width: 1920px) {
    height: 55vh;
    
    float: right;
    position: absolute;
    right: 7.6%;
    top: 7%;
    box-shadow: 0px 10px 26px #00000033;
    opacity: 1;
    z-index: 3;
    backdrop-filter: blur(32px);
    -webkit-backdrop-filter: blur(32px);
    border-radius: 10px;
  }
}

.notificationListContainer {
  display: flex;
  width:100%;
  padding:40px;
  border-bottom: 1px solid #f6f6f6;
  background-color: #fcfcfc;
  .Date-Time-Details{
    text-align: left;
  }
  &.notificationListContainerRead {
    background-color: #fff;
  }
}
.orange {
  background: #FF8543;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;

  img {
    margin-left: 25%;
    margin-top: 8%;
  }
}

.reqNotificationtText {
  font-size: 0.9rem;
  font-style: "Roboto";
  width: 100%;

  .submitFeedback {
    text-align: right;

    button {
      height: 30px;
      width: 150px;
      background-color: #09577a;
      text-transform: none;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}

.notification-list {
  // height: 70%;
  // overflow: hidden;
  article {
    width: 100%;
    min-height: 4.5rem;
    padding:10px 30px;
  }
}

.notification-header {
  text-align: left;
  font: normal normal medium 20px/48px Roboto;
  letter-spacing: 0px;
  color: #09577a;
  padding-left: 5%;
  padding-top: 5%;
  font-weight: 600;
}

.showMoreNotification {
  text-align: center;
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0.36px;
  color: #09577a;
  opacity: 1;
  cursor: pointer;
}

// UserProfileIcon-popup
.userProfileIconPopUp {
  width: 12%;
  float: right;
  position: absolute;
  right: 6.9%;
  top: 70px;
  // background: transparent linear-gradient(115deg, #fff, hsla(38, 10%, 100%, 0.6) 50%, hsla(0, 0%, 100%, -0.298039)) 0 0
  //   no-repeat padding-box;
  background-color: #fff;
  box-shadow: 0px 10px 26px #00000033;
  opacity: 1;
  z-index: 7;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  border-radius: 10px;
  @media screen and (min-width: "1440px") {
    right: 5%;
    width: 10%;
  }
  ul {
    padding-left: 0px !important;
    list-style-type: none;
    margin-top: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
      color: #000;
      font-size: 0.9rem;
      cursor: pointer;
      //width: 80%;
      // &:hover{
      //   background-color: rgba(0, 0, 0, 0.04);
      // }
    }
    li {
      //width: 80%;
      height: 2rem;
      cursor: pointer;
      height: 2rem;
      cursor: pointer;
      padding-top: 5px;
      padding-left: 20px;
      font-size: 14px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.notificationText {
  //max-height: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userImgNavContainer {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.navigationReqBtn:hover {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
