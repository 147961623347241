/*
 * Events css File
*/

.eventWrapper {
  height: 80vh;
  width: 95%;
  margin: 0 auto;
  padding: 0 75px;
  font-size: 12px;
  font-family: "Roboto";
  @media screen and (max-width: 1024px) {
    padding: 0 0;
    height: 65vh;
    width: 95%;
    margin: 0 auto;
    margin-top: 14em !important;
    padding-bottom: 30px;
  }
  @media screen and (max-width: "320px") {
    height: 56vh;
  }
  @media screen and (min-width: "1400px") {
    height: 85vh;
  }
  @media screen and (min-width: "1920px") {
    padding: 20px 75px;
  }
  .rbc-toolbar {
    font-size: 12px;

    @media screen and (max-width: 1024px) {
      .rbc-btn-group:first-child {
        button:first-child {
          position: absolute;
          top: 9em;
          left: 20px;
        }
        button:last-child {
          position: absolute;
          right: 10px;
        }
      }
      .rbc-btn-group:last-child {
        position: absolute;
        top: 9em;
        right: 10px;
      }
    }
  }
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 20px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      padding-right: 4em;
    }
  }
  .rbc-toolbar span:nth-child(1) button {
    background: #09577a !important;
    color: white !important;
    box-shadow: none !important;
    border: 0 !important;
    margin: 0 10px !important;
    border-radius: 5px !important;
  }
  .rbc-toolbar span:nth-child(1) button:first-child {
    background: #09577a !important;
    color: white !important;
    box-shadow: none !important;
    border: 0 !important;
    margin: 0 10px 0 0 !important;
    border-radius: 5px !important;
  }


  @media screen and (max-width: 1024px) {
    .rbc-toolbar:first-child span:nth-child(1) button:nth-child(2) {
      background: #09577a !important;
      color: #fff !important;
      box-shadow: none !important;
      border: 0 !important;
      margin: 0 10px 0 0 !important;
      border-radius: 5px !important;
    }
  }

  .rbc-toolbar span:nth-child(3) button {
    margin: 0 10px !important;
    border-radius: 6px !important;
    font-size: 12px !important;
    border: 0 !important;
    border-radius: 5px !important;
  }
  .rbc-toolbar span:nth-child(3) button:last-child {
    margin: 0 0 0 10px !important;
    border-radius: 6px !important;
    font-size: 12px !important;
    border: 0 !important;
    border-radius: 5px !important;
  }
  .rbc-toolbar span:nth-child(3) .rbc-active {
    background: #09577a !important;
    color: white !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  .rbc-month-header {
    height: 40px;
  }
  .rbc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d4f0fc;
    // font-weight: 400;
    font-family: "Roboto";
    font-size: 12px;
    background: #2d7beb;
    color: white;
  }
  .rbc-date-cell {
    color: #8a888a;
    &.rbc-now {
      a {
        font-size: 20px;

        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }
  }
}

.eventDialogWrapper .MuiDialog-paperScrollPaper {
  display: flex;
  height: calc(100% - 170px);
  //height: 75vh;
  flex-direction: column;
  overflow: auto;
  width: 25%;
  margin: 0 auto;

    @media screen and (max-width: 1024px) {
    width: 80%;
    height: calc(100% - 240px);
  }

  @media screen and (max-width: "415px") and  (max-height: "825px") {
    width: 80%;
    height: calc(100% - 370px);
  }
  @media screen and (max-width: "415px") and  (max-height: "745px") {
    width: 80%;
    height: calc(100% - 270px);
  }

  @media screen and (max-width: "375px") {
    width: 80%;
    height: calc(100% - 240px);
  }

  @media screen and (max-width: "360px") {
    width: 80%;
    height: calc(100% - 190px);
  }

  @media screen and (max-width: "320px") {
    width: 80%;
    height: calc(100% - 90px);
  }

  @media only screen and (min-width: 1024px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    width: 25%;
    height: calc(100% - 270px);
}





  @media screen and (min-width: "1440px") {
    height: calc(100% - 440px);
  }
}
// .eventDialogTitle {
//   @media screen and (min-width: "1440px") {
//     padding: 40px !important;
//   }
// }
.eventDialogTitle .MuiTypography-h6 {
  color: #09577a;
  text-transform: capitalize;
  @media screen and (min-width: "1440px") {
    font-size: 1.6rem;
  }
}
.closeEventDialog {
  fill: rgb(153, 138, 138) !important;
  float: right;
  cursor: pointer;
  color: #09577a;
  font-size: 0.9rem;
  @media screen and (min-width: "1440px") {
    font-size: 1rem;
  }
}
.eventDialogContent {
  display: flex;
  flex-direction: column;
  font-size: 0.82rem;
  padding: 0 30px;

  .MuiSvgIcon-root {
    width: 0.9em;
    height: 0.9em;
  }

  @media screen and (min-width: "1440px") {
    padding: 0 40px 40px;
  }
  article {
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
    @media screen and (min-width: "1440px") {
      font-size: 1rem;
    }
    svg {
      color: #2d7beb;
      font-size: 1rem;
      @media screen and (min-width: "1440px") {
        font-size: 2rem;
      }
    }
    div {
      p {
        font-size: 0.8rem;
        margin-bottom: 0;
        @media screen and (min-width: "1440px") {
          font-size: 1rem;
        }
      }
    }
    p {
      font-size: 0.8rem;
      @media screen and (min-width: "1440px") {
        font-size: 1rem;
      }
    }
    label {
      font-weight: bold;
      font-size: 0.8rem;
      @media screen and (min-width: "1440px") {
        font-size: 1rem;
      }
    }
  }
  article:first-child {
    //font-weight: bold;
    display: flex;
    font-size: 0.9rem;
    @media screen and (min-width: "1440px") {
      font-size: 1rem;
      padding: 10px 0;
    }
    svg {
      font-size: 1.5rem;
      margin-left: -3px;
      @media screen and (min-width: "1440px") {
        font-size: 2rem;
      }
    }
    span {
      margin-left: 5px;
    }
  }
}
.eventStartEndTime {
  display: flex;
  section {
    width: 50%;
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 0.5rem;
      label {
        font-size: 0.8rem;
        @media screen and (min-width: "1440px") {
          font-size: 1rem;
        }
      }
      article {
        font-size: 0.8rem;
        @media screen and (min-width: "1440px") {
          font-size: 1rem;
        }
      }
    }

    svg {
      margin-top: 0.5rem;
    }
  }
}
.eventHcoName {
  display: flex;
  svg {
    margin-top: 0.5rem;
  }
  div {
    padding-left: 0.7rem;
  }
}
