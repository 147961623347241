.siteDown-Container {
  background: url("../../assets/images/sitedown.png") no-repeat center fixed;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

// .siteDown-text {
//   color: #09577a;
//   text-align: center;
// }
