@media screen and (min-width: 320px) and (max-width: 1024px) {
  .req-mainContainer {
    header {
      width: 100%;
      padding-top: 20px;
      padding-left: 0px;
      padding-right: 0px;
      .showRequestTab {
        display: block;
      }
      .hideRequestTab {
        display: none;
      }
      .Mui-selected {
        font-size: 0.7rem !important;
      }
      .MuiTab-wrapper {
        text-align: left;
        font-size: medium;
        font-weight: normal;
        letter-spacing: 0px;
        margin-left: 20px;
        color: #295788;
        justify-content: flex-start;
        align-items: center;
        opacity: 1;
      }
      .MuiBadge-anchorOriginTopRightRectangle {
        @media screen and (min-width: 320px) and (max-width: 600px) {
          right: 0rem !important;
        }
        @media screen and (min-width: 601px) and (max-width: 1024px) {
          right: 6.5rem !important;
        }
      }
    }
    .MuiTab-root {
      min-width: 45% !important;
    }
    .MuiBox-root {
      //   display:inline-flex;
      .createReq-mainContainer {
        width: 100vw;
        .selectedRequestContent {
          width: 95%;
          border: none;
          box-shadow: none;
          background-color: #fcfcfc;
          color: #000;
          .reqActionBtnContainer {
            margin-top: 20%;
            position: inherit;
            left: 0%;
            justify-content: space-between;
            button {
              width: 80% !important;
              max-width: 45%;
            }
            .saveAsDraft {
              width: 50% !important;
            }
          }
          .form-fieldsContainer {
            min-height: 0rem !important;
          }
          .userFilledEntity {
            width: 100%;
            margin: 20px 0;

            input:not(.MuiAutocomplete-input) {
              width: 100%;
            }
            .MuiInputLabel-formControl {
              top: 0px;
            }
            .MuiFormControl-root {
              width: 100% !important;
              margin: 15px 0;
              .MuiInputBase-root {
                width: 100% !important;
                border: 1px solid #eeeeee;
                padding: 5px 0px;
                // background-color: #fcfcfc;
                color: #000;
                &::before {
                  border-bottom: none;
                }
                &:hover {
                  &::before {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
        .createReq-categoryOption-mainContainer {
          width: 100vw;
          .categoryOptionContent-service {
            width: 100%;
            box-shadow: none;
            position: relative;
            margin-left: 0;
            padding: 0px 0px 0px 0px 15px;
            display: inline-flex;
            justify-content: space-between;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            border-bottom: 1px solid #eeeeee;
            p {
              width: 50%;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: center;
              margin: 5px 0;
              display: flex;
              max-width: 75px;
              padding: 0 4px;
            }
            article {
              width: 120px;
              text-align: center;
              height: 150px;
              margin-right: 10px;
              margin: 0;
              padding: 20px 3px;
              box-shadow: none;
              &:first-child {
                padding-left: 25px;
              }
              &.whiteText {
                background-color: #fcfbfc !important;

                p {
                  color: #000 !important;
                }
                div {
                  background-color: #09577a;
                }
              }
              div {
                height: 65px;
                width: 70px !important;
                border-radius: 10px;
                border: 1px solid #53bff9;
                border: 1px solid #53bff9;
                img {
                  width: 80% !important;
                  height: 56px;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
