


.closeSponsoredPopup {
  float: right;
  margin: 1%;
  cursor: pointer;
}

.closeSponsoredPopup.MuiSvgIcon-root{
    fill: #09577a !important;
  }

.MuiDialog-paper{
    //min-width: 30%;
    .sponsoredPopupDetails{
        font-family: 'Roboto';
        h5{
            color: #09577a;
            text-align: center;
            margin: 4%;
        }
    
        .sponsoredPopUptextDesc{
            color: #09577a;
            text-align: center;
            font-size: 0.9rem;
            font-family: 'Roboto';
        }
    }
    // @media screen and (min-width:1024px) {
    //     min-width: 80%;
    // }
  
}

.MuiDialogActions-root{
justify-content: center !important;
}

.btnContainer_sponsored{
    font-family: 'Roboto';
    .sponsoredpopUpBtn {
        min-width: 20% !important;
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #E1E1E1 !important;
        border-radius: 5px;
        text-transform: none !important;
        margin:5% 0 5% 0;
        color: #0F1C49;
        box-shadow: none;

      &:hover{
        background: #09577a 0% 0% no-repeat padding-box !important;
        color: #fff !important;
        border: 1px solid #09577a !important;
        opacity: .8;
    }
    }
}

.pocDetails{
    margin-left: 10%;
    text-align:left ;
    color: #09577a;
    h5{
        color: #09577a; 
        margin-left: 0% !important;
        margin-bottom: 1% !important;
    }
    p{
        margin-bottom: 1% !important;
    }
}

.sampleTextMsg{
    text-align:center ;
    color: #09577a;
}

.confirmBtn{
    background: #09577a 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #09577a !important;
    min-width: 20% !important;
    border-radius: 5px;
    text-transform: none !important;
    margin:5% 0 5% 5% !important;
    box-shadow: none;
}

.sponsoredPopup2{
    h5{
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .MobileHomePage-popover {
        .MuiDialog-scrollPaper{
        display: flex !important;
        width: 100% !important;
        min-height:200px;
        //margin: 40vh auto !important;
        }
        .MuiButton-root {
            font-size:12px;
            font-weight:400;
        }
    }
}