.disclaimer-rootContainer {
  width: 100%;
}
.disclaimer-textContainer {
  // background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #eeeeee;
  border-radius: 4px;
  opacity: 1;
  width: 80%;
  margin: 0 auto;
  white-space: pre-line;
  height: 75vh;
  overflow: auto;
  p,
  li {
    font-size: 14px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 6em !important;
  }
}

.titleSection {
  display: flex;
  height: 4rem;
  background: transparent linear-gradient(94deg, #0a358e 0%, #53bff9 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headingPage {
    justify-content: flex-end;
  }

  h5 {
    color: #fff !important;
    padding-top: 2%;

    @media screen and (min-width: 1600px) {
      margin-right: 4%;
    }
    @media screen and (min-width: 1600px) {
      margin-right: 10%;
    }
    @media screen and (min-width: 1600px) {
      margin-right: 10%;
    }
    @media screen and (min-width: 1920px) {
      margin-right: 18%;
    }
  }
}

.mobile-FAQHeader {
  padding-left: 2em;
  color: #295788;
}
