$myColor: green;

h1 {
  color: $myColor;
}

// SHARED.SCSS
// DEDICATED VARIABLE KA APNA EK FILE

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.watsappIcon {
  position: fixed;
  z-index: 5;
  top: 80%;
  left: 93%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 10px #00000029;
  border-radius: 40px 0px 0px 40px;
  width: 100%;
  height: 45px;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    left: 87%;
    top: 75%;
    background: none;
    box-shadow: none;
    border-radius: none;
  }

  img {
    height: 80%;
    margin-left: 2%;
    width: 2%;
    margin-top: 0.3%;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
      height: 80%;
      // margin-left: 4%;
      width: 10%;
    }
  }
}
